(function($) {
    $.fn.boxWidget.activateHdr = function(e, ctx) {
        if (ctx == void 0 || ctx == null) ctx = document;
        $('.box > .box-header.box-header-active', ctx).each(function() {
            $(this).on('click', function(e) {
                if ($(e.target).parents('.box-tools').length != 0) { return; }
                $(this).parent().boxWidget('toggle');
            });
        });
    };
    $(window).on('load', $.fn.boxWidget.activateHdr);
} (jQuery));

(function($) {
    $.fn.boxWidget.alterIcon = function(e, ctx) {
        if (ctx == void 0 || ctx == null) ctx = document;
        $('.box > .box-header > .box-tools.box-tools-alternate[data-collapse-icon][data-expand-icon]', ctx).each(function() {
            let opts = $(this).closest('.box').data('lte.boxwidget').options;
            if (typeof opts === "object") {
                opts.collapseIcon = this.dataset.collapseIcon;
                opts.expandIcon = this.dataset.expandIcon;
            }
        });
    };
    $(window).on('load', $.fn.boxWidget.alterIcon);
} (jQuery));